<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap text-center">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowDetailForm(row.item)"
            >
              View
            </b-button>
            <b-button
              size="sm"
              variant="success"
              @click="onShowEditForm(row.item)"
            >
              Update
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-view-request-detail"
      title="View Details"
      scrollable
      no-close-on-backdrop
      size="lg"
    >
      <b-tabs>
        <b-tab
          title="Request Details"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Request Date</label>
                <b-input
                  :value="dateTimeFormatter(selected.call_request?.created_at)"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Customer Name</label>
                <b-input
                  :value="`${selected.call_request?.customer?.first_name} ${selected.call_request?.customer?.last_name}`"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Mobile Number</label>
                <b-input
                  :value="selected.call_request?.customer?.mobile_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Status</label>
                <b-input
                  :value="selected.call_request?.status"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Remarks</label>
                <b-textarea
                  :value="selected.call_request?.remarks"
                  rows="10"
                  max-rows="10"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Communication Logs"
        >
          <b-table
            ref="customerLogTable"
            hover
            responsive
            class="mt-2"
            :per-page="tableCallRequestLogSettings.perPage"
            :current-page="tableCallRequestLogSettings.currentPage"
            :items="myCallRequestLogProvider"
            :fields="tableCallRequestLogSettings.fields"
            :sort-by.sync="tableCallRequestLogSettings.sortBy"
            :sort-desc.sync="tableCallRequestLogSettings.sortDesc"
            :sort-direction="tableCallRequestLogSettings.sortDirection"
            :filter="tableCallRequestLogSettings.filter"
            :filter-included-fields="tableCallRequestLogSettings.filterOn"
            show-empty
          >

            <template #cell(index)="data">
              {{ tableCallRequestLogSettings.currentPage * tableCallRequestLogSettings.perPage - tableCallRequestLogSettings.perPage + (data.index + 1) }}
            </template>

            <template #cell()="data">
              <div class="text-nowrap">
                {{ data.value }}
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onViewCallRequestLog(row.item)"
                >
                  View
                </b-button>
              </div>
            </template>

          </b-table>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableCallRequestLogSettings.perPage"
                  :options="tableCallRequestLogSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableCallRequestLogSettings.currentPage"
                :total-rows="tableCallRequestLogSettings.totalRows"
                :per-page="tableCallRequestLogSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-call-request-log"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Channel</label>
            <b-input
              :value="selected.log?.channel"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Call Status</label>
            <b-input
              :value="selected.log?.call_status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Unsuccessful Reason</label>
            <b-input
              :value="selected.log?.unsuccessful_reason_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-edit-form"
      scrollable
      no-close-on-backdrop
      title="Update Customer"
      size="xl"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-tabs
            class="px-2"
            no-body
          >
            <b-tab
              title="Personal Information"
              active
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="first name"
                    vid="first_name"
                    rules="required|max:50"
                  >
                    <b-form-group>
                      <label for="first_name"><strong>First name</strong></label>
                      <b-input
                        id="first_name"
                        v-model="customer.first_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter first name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="last name"
                    vid="last_name"
                    rules="required|max:50"
                  >
                    <b-form-group>
                      <label for="last_name"><strong>Last Name</strong></label>
                      <b-input
                        id="last_name"
                        v-model="customer.last_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter last name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="customer type"
                    vid="customer_type"
                  >
                    <b-form-group>
                      <label for="customer_type"><strong>Customer Type</strong></label>
                      <b-form-select
                        id="customer_type"
                        v-model="customer.customer_type"
                        :options="options.customer_types"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Customer Type --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="lead tag"
                    vid="lead_tag"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="lead_tag"><strong>Lead Tag</strong></label>
                      <b-form-select
                        id="lead_tag"
                        v-model="customer.lead_tag"
                        :options="list.lead_tags"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Lead Tag --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="mobile number"
                    vid="mobile_number"
                    rules="required|min:11|max:11"
                  >
                    <b-form-group>
                      <label for="mobile_number"><strong>Mobile Number</strong></label>
                      <b-input
                        id="mobile_number"
                        v-model="customer.mobile_number"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter contact number"
                        autocomplete="off"
                        maxlength="11"
                        :disabled="true"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="email"
                    vid="email"
                    rules="email|max:100"
                  >
                    <b-form-group>
                      <label for="email"><strong>Email</strong></label>
                      <b-input
                        id="email"
                        v-model="customer.email"
                        type="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter email"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="birth date"
                    vid="birth_date"
                  >
                    <b-form-group>
                      <label for="birth_date"><strong>Birth Date</strong></label>
                      <b-input
                        id="birth_date"
                        v-model="customer.birth_date"
                        type="date"
                        placeholder="enter birth date"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="gender"
                    vid="gender"
                  >
                    <b-form-group>
                      <label for="gender"><strong>Gender</strong></label>
                      <b-form-select
                        id="gender"
                        v-model="customer.gender"
                        :options="options.genders"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Gender --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="primary dialect"
                    vid="primary_dialect"
                    rules=""
                  >
                    <b-form-group>
                      <label for="primary_dialect"><strong>Primary Dialect</strong></label>
                      <b-form-select
                        id="primary_dialect"
                        v-model="customer.primary_dialect"
                        :options="list.dialects"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Primary Dialect --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="secondary dialect"
                    vid="secondary_dialect"
                    rules=""
                  >
                    <b-form-group>
                      <label for="secondary_dialect"><strong>Secondary Dialect</strong></label>
                      <b-form-select
                        id="secondary_dialect"
                        v-model="customer.secondary_dialect"
                        :options="list.dialects"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Secondary Dialect --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="occupation"
                    vid="occupation"
                    rules=""
                  >
                    <b-form-group>
                      <label for="occupation"><strong>Occupation</strong></label>
                      <b-form-select
                        id="occupation"
                        v-model="customer.occupation"
                        :options="list.occupations"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Occupation --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="other occupation remarks"
                    vid="other_occupation"
                    rules="max:100"
                  >
                    <b-form-group>
                      <label for="other_occupation"><strong>Other Occupation Remarks</strong></label>
                      <b-input
                        id="other_occupation"
                        v-model="customer.other_occupation"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter other occupation remarks"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="dealers"
                    vid="dealers"
                    rules=""
                  >
                    <b-form-group>
                      <label for="dealers"><strong>Branch / Dealer</strong></label>
                      <v-select
                        id="dealers"
                        v-model="customer.dealers"
                        :options="list.dealers"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="business_name"
                        :disabled="state.busy"
                        multiple
                      >
                        <template #option="{ business_name }">
                          <strong>{{ business_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Dealers
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col> -->

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="dealer"
                    vid="other_dealer"
                    rules="max:100"
                  >
                    <b-form-group>
                      <label for="other_dealer"><strong>Dealer</strong></label>
                      <b-input
                        id="other_dealer"
                        v-model="customer.other_dealer"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter dealer"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="street name"
                    vid="street_name"
                    rules="max:100"
                  >
                    <b-form-group>
                      <label for="street_name"><strong>Street Name</strong></label>
                      <b-input
                        id="street_name"
                        v-model="customer.street_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter street name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="province"
                    vid="province"
                    rules=""
                  >
                    <b-form-group>
                      <label for="province"><strong>Province</strong></label>
                      <v-select
                        id="province"
                        v-model="selected.province"
                        :options="list.provinces"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="province_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ province_name }">
                          <strong>{{ province_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Provinces
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="municipality"
                    vid="municipality"
                    rules=""
                  >
                    <b-form-group>
                      <label for="municipality"><strong>Municipality</strong></label>
                      <v-select
                        id="municipality"
                        v-model="selected.municipality"
                        :options="list.municipalities"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="municipality_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ municipality_name }">
                          <strong>{{ municipality_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Municipalities
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="barangay"
                    vid="barangay"
                    rules=""
                  >
                    <b-form-group>
                      <label for="barangay"><strong>Barangay</strong></label>
                      <v-select
                        id="arangay"
                        v-model="selected.barangay"
                        :options="list.barangays"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="barangay_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ barangay_name }">
                          <strong>{{ barangay_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Barangays
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="farm address"
                    vid="farm_address"
                    rules="max:500"
                  >
                    <b-form-group>
                      <label for="farm_address"><strong>Farm Address</strong></label>
                      <b-textarea
                        id="farm_address"
                        v-model="customer.farm_address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter farm address"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col> -->

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="purchasing behavior"
                    vid="purchasing_behavior"
                    rules=""
                  >
                    <b-form-group>
                      <label for="purchasing_behavior"><strong>Purchasing Behavior</strong></label>
                      <b-form-select
                        id="purchasing_behavior"
                        v-model="customer.purchasing_behavior"
                        :options="options.purchasing_behaviors"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Purchasing Behavior --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="day of purchase"
                    vid="purchase_day"
                    rules=""
                  >
                    <b-form-group>
                      <label for="purchase_day"><strong>Day of Purchase</strong></label>
                      <b-form-select
                        id="purchase_day"
                        v-model="customer.purchase_day"
                        :options="options.purchase_days"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Day of Purchase --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="purchase time"
                    vid="purchase_time"
                    rules=""
                  >
                    <b-form-group>
                      <label for="purchase_time"><strong>Time Of Purchase</strong></label>
                      <b-form-select
                        id="purchase_time"
                        v-model="customer.purchase_time"
                        :options="options.purchase_times"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Time Of Purchase --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="digital level"
                    vid="digital_level"
                    rules=""
                  >
                    <b-form-group>
                      <label for="digital_level"><strong>Digital Level</strong></label>
                      <b-form-select
                        id="digital_level"
                        v-model="customer.digital_level"
                        :options="options.digital_levels"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Digital Level --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="service of product"
                    vid="service_product"
                    rules=""
                  >
                    <b-form-group>
                      <label for="service_product"><strong>Service of Product</strong></label>
                      <b-form-select
                        id="service_product"
                        v-model="customer.service_product"
                        :options="options.service_products"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Service Of Product --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="mode of communication"
                    vid="mode_of_communication"
                    rules=""
                  >
                    <b-form-group>
                      <label for="mode_of_communication"><strong>Mode of Communication</strong></label>
                      <b-form-select
                        id="mode_of_communication"
                        v-model="customer.mode_of_communication"
                        :options="options.mode_of_communications"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Mode of Communication --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="hog raiser category"
                    vid="hog_raiser_category"
                    rules=""
                  >
                    <b-form-group>
                      <label for="hog_raiser_category"><strong>Hog Raiser Category</strong></label>
                      <v-select
                        id="hog_raiser_category"
                        v-model="selected.hog_raiser_category"
                        :options="filteredHogRaiserCategories"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="category_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ category_name }">
                          <strong>{{ category_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Hog Raiser Categories
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="poultry raiser category"
                    vid="poultry_raiser_category"
                    rules=""
                  >
                    <b-form-group>
                      <label for="poultry_raiser_category"><strong>Poultry Raiser Category</strong></label>
                      <v-select
                        id="poultry_raiser_category"
                        v-model="selected.poultry_raiser_category"
                        :options="filteredPoultryRaiserCategories"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="category_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ category_name }">
                          <strong>{{ category_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Poultry Raiser Categories
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab title="Custom Fields">
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="field"><strong>Custom Field</strong></label>
                    <b-form-select
                      id="field"
                      v-model="customField.field"
                      :options="list.custom_fields"
                      :disabled="state.busy"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Custom Field --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="field_value"><strong>Custom Value</strong></label>
                    <b-textarea
                      id="field_value"
                      v-model="customField.field_value"
                      rows="6"
                      max-rows="6"
                      placeholder="enter value"
                      autocomplete="off"
                      :disabled="(state.busy || customField.field === null)"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-3"
                >
                  <b-button
                    type="button"
                    variant="success"
                    class="mr-2"
                    :disabled="customFieldInserButttonDisabledState"
                    @click="onCustomFieldInsert"
                  >
                    Insert Field
                  </b-button>

                  <b-button
                    type="button"
                    variant="secondary"
                    @click="onCustomFieldRefresh"
                  >
                    Refresh List
                  </b-button>
                </b-col>

                <b-col
                  v-for="(field, key) in customer.custom_fields"
                  :key="key"
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label :for="field.field_label"><strong>{{ field.field_label }}</strong></label>
                    <b-textarea
                      :id="field.field_label"
                      v-model="field.field_value"
                      rows="6"
                      max-rows="6"
                      autocomplete="off"
                      :disabled="true"
                    />
                    <a
                      v-if="isNaN(parseInt(field.id))"
                      href="javascript:void(0);"
                      class="text-danger"
                      @click="onCustomFieldRemove(field)"
                    >Remove Field</a>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab title="Live Stock Histories">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="live_stock"><strong>Live Stock</strong></label>
                    <v-select
                      id="live_stock"
                      v-model="selected2.live_stock"
                      :options="list.live_stocks"
                      label="live_stock_name"
                      :disabled="state.busy"
                      placeholder="-- Please Select Live Stock --"
                    >
                      <template #option="{ live_stock_name }">
                        <span>{{ live_stock_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Live Stocks
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="live_stock_type"><strong>Live Stock Type</strong></label>
                    <v-select
                      id="live_stock_type"
                      v-model="selected2.live_stock_type"
                      :options="list.live_stock_types"
                      label="type_name"
                      :disabled="state.busy"
                      placeholder="-- Please Select Live Stock Type --"
                    >
                      <template #option="{ type_name }">
                        <span>{{ type_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Live Stock Types
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="live_stock_count"><strong>Live Stock Count</strong></label>
                    <b-input
                      id="live_stock_count"
                      v-model="liveStockHistory.live_stock_count"
                      type="number"
                      placeholder="enter live stock count"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                    class="mr-2"
                    type="button"
                    :variant="liveStockHistoryButtonVariant"
                    :disabled="(state.busy || liveStockHistoryButtonDisabledState)"
                    @click="onLiveStockHistoryButtonClick"
                  >
                    {{ liveStockHistoryButtonLabel }}
                  </b-button>
                  <b-button
                    v-if="state.liveStockHistoryEditing"
                    type="button"
                    variant="danger"
                    :disabled="state.busy"
                    @click="onLiveStockHistoryCancelUpdate"
                  >
                    Cancel Update
                  </b-button>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-table
                    ref="table-live-stock-histories"
                    hover
                    responsive
                    :per-page="tableLiveStockHistories.perPage"
                    :current-page="tableLiveStockHistories.currentPage"
                    :fields="tableLiveStockHistories.fields"
                    :items="filteredLiveStockHistories"
                    :sort-by.sync="tableLiveStockHistories.sortBy"
                    :sort-desc.sync="tableLiveStockHistories.sortDesc"
                    :sort-direction="tableLiveStockHistories.sortDirection"
                    :filter="tableLiveStockHistories.filter"
                    :filter-included-fields="tableLiveStockHistories.filterOn"
                    show-empty
                  >
                    <template #cell(index)="data">
                      {{ tableLiveStockHistories.currentPage * tableLiveStockHistories.perPage - tableLiveStockHistories.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="success"
                          class="mr-2"
                          :disabled="state.liveStockHistoryEditing"
                          @click="onLiveStockHistoryEdit(row.item)"
                        >
                          Edit
                        </b-button>

                        <b-button
                          v-if="isNaN(parseInt(row.item.id))"
                          size="sm"
                          variant="danger"
                          :disabled="state.liveStockHistoryEditing"
                          @click="onLiveStockHistoryRemove(row.item)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                        <b-select
                          v-model="tableLiveStockHistories.perPage"
                          :options="tableLiveStockHistories.pageOptions"
                          size="sm"
                        />
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        v-model="tableLiveStockHistories.currentPage"
                        :total-rows="tableLiveStockHistories.totalRows"
                        :per-page="tableLiveStockHistories.perPage"
                        first-number
                        last-number
                        pills
                        prev-text="Prev"
                        next-text="Next"
                        aria-controls="table"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Feed Brand Histories">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="live_stock"><strong>Live Stock</strong></label>
                    <v-select
                      id="live_stock"
                      v-model="selected3.live_stock"
                      :options="list.live_stocks"
                      label="live_stock_name"
                      :disabled="state.busy"
                      placeholder="-- Please Select Live Stock --"
                    >
                      <template #option="{ live_stock_name }">
                        <span>{{ live_stock_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Live Stocks
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="feed_brand"><strong>Feed Brand</strong></label>
                    <v-select
                      id="feed_brand"
                      v-model="selected3.feed_brand"
                      :options="list.feed_brands"
                      label="brand_name"
                      :disabled="state.busy"
                      placeholder="-- Please Select Feed Brand --"
                    >
                      <template #option="{ brand_name }">
                        <span>{{ brand_name }}</span>
                      </template>
                      <template #no-options="">
                        No Available Feed Brands
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                    class="mr-2"
                    type="button"
                    :variant="feedBrandHistoryButtonVariant"
                    :disabled="(state.busy || feedBrandHistoryButtonDisabledState)"
                    @click="onFeedBrandHistoryButtonClick"
                  >
                    {{ feedBrandHistoryButtonLabel }}
                  </b-button>
                  <b-button
                    v-if="state.feedBrandHistoryEditing"
                    type="button"
                    variant="danger"
                    :disabled="state.busy"
                    @click="onFeedBrandHistoryCancelUpdate"
                  >
                    Cancel Update
                  </b-button>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-table
                    ref="table-feed-brand-histories"
                    hover
                    responsive
                    :per-page="tableFeedBrandHistories.perPage"
                    :current-page="tableFeedBrandHistories.currentPage"
                    :fields="tableFeedBrandHistories.fields"
                    :items="filteredFeedBrandHistories"
                    :sort-by.sync="tableFeedBrandHistories.sortBy"
                    :sort-desc.sync="tableFeedBrandHistories.sortDesc"
                    :sort-direction="tableFeedBrandHistories.sortDirection"
                    :filter="tableFeedBrandHistories.filter"
                    :filter-included-fields="tableFeedBrandHistories.filterOn"
                    show-empty
                  >
                    <template #cell(index)="data">
                      {{ tableFeedBrandHistories.currentPage * tableFeedBrandHistories.perPage - tableFeedBrandHistories.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="success"
                          :disabled="state.feedBrandHistoryEditing"
                          class="mr-2"
                          @click="onFeedBrandHistoryEdit(row.item)"
                        >
                          Edit
                        </b-button>

                        <b-button
                          v-if="isNaN(parseInt(row.item.id))"
                          size="sm"
                          variant="danger"
                          :disabled="state.feedBrandHistoryEditing"
                          @click="onFeedBrandHistoryRemove(row.item)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                        <b-select
                          v-model="tableFeedBrandHistories.perPage"
                          :options="tableFeedBrandHistories.pageOptions"
                          size="sm"
                        />
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        v-model="tableFeedBrandHistories.currentPage"
                        :total-rows="tableFeedBrandHistories.totalRows"
                        :per-page="tableFeedBrandHistories.perPage"
                        first-number
                        last-number
                        pills
                        prev-text="Prev"
                        next-text="Next"
                        aria-controls="table"
                      />
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab
              title="Call Disposition"
            >
              <b-row>
                <b-col cols="12">
                  <b-table
                    ref="tableChannelDisposition"
                    hover
                    responsive
                    class="mt-2"
                    :per-page="tableCommunicationLogSettings.perPage"
                    :current-page="tableCommunicationLogSettings.currentPage"
                    :items="myLogProvider"
                    :fields="tableCommunicationLogSettings.fields"
                    :sort-by.sync="tableCommunicationLogSettings.sortBy"
                    :sort-desc.sync="tableCommunicationLogSettings.sortDesc"
                    :sort-direction="tableCommunicationLogSettings.sortDirection"
                    :filter="tableCommunicationLogSettings.filter"
                    :filter-included-fields="tableCommunicationLogSettings.filterOn"
                    show-empty
                  >
                    <template #cell(index)="data">
                      {{ tableCommunicationLogSettings.currentPage * tableCommunicationLogSettings.perPage - tableCommunicationLogSettings.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="secondary"
                          class="mr-2"
                          @click="onViewCommunicationLogDetail(row.item)"
                        >
                          View
                        </b-button>
                      </div>
                    </template>

                  </b-table>

                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                        <b-select
                          v-model="tableCommunicationLogSettings.perPage"
                          :options="tableCommunicationLogSettings.pageOptions"
                          size="sm"
                        />
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        v-model="tableCommunicationLogSettings.currentPage"
                        :total-rows="tableCommunicationLogSettings.totalRows"
                        :per-page="tableCommunicationLogSettings.perPage"
                        first-number
                        last-number
                        pills
                        prev-text="Prev"
                        next-text="Next"
                        aria-controls="table"
                      />
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <b-form-group>
                    <label><strong>Previous Remarks</strong></label>
                    <b-textarea
                      :value="allComments"
                      rows="10"
                      max-rows="10"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="call status"
                    vid="call_status"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="call_status"><strong>Call Status</strong></label>
                      <v-select
                        id="call_status"
                        v-model="selected.call_status"
                        :options="options.call_statuses"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="text"
                        :disabled="state.busy"
                        placeholder="-- Please Select Call Status --"
                      >
                        <template #option="{ text }">
                          <span>{{ text }}</span>
                        </template>
                        <template #no-options="">
                          No Available Call Statuses
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="unsuccessful reason"
                    vid="unsuccessful_reason"
                    :rules="rulesUnsuccessfulReason"
                  >
                    <b-form-group>
                      <label for="unsuccessful_reason"><strong>Unsuccessful Reason</strong></label>
                      <v-select
                        id="unsuccessful_reason"
                        v-model="selected.unsuccessful_reason"
                        :options="list.unsuccessful_reasons"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="reason_name"
                        :disabled="(state.busy || customer.call_status !== 'Unsuccessful')"
                        placeholder="-- Please Select Unsuccessful Reason --"
                      >
                        <template #option="{ reason_name }">
                          <span>{{ reason_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Unsuccessful Reasons
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="disposition type"
                    vid="disposition_type"
                    :rules="rulesDispositionType"
                  >
                    <b-form-group>
                      <label for="disposition_type"><strong>Disposition Type</strong></label>
                      <v-select
                        id="disposition_type"
                        v-model="selected.disposition_type"
                        :options="list.disposition_types"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="type_name"
                        :disabled="(state.busy || customer.call_status !== 'Connected')"
                        placeholder="-- Please Select Disposition Type --"
                      >
                        <template #option="{ type_name }">
                          <span>{{ type_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Disposition Types
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="disposition"
                    vid="disposition"
                    :rules="rulesDisposition"
                  >
                    <b-form-group>
                      <label for="sub_disposition"><strong>Disposition</strong></label>
                      <v-select
                        id="disposition"
                        v-model="selected.disposition"
                        :options="list.dispositions"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="disposition_name"
                        :disabled="(state.busy || customer.call_status !== 'Connected')"
                        placeholder="-- Please Select Disposition --"
                      >
                        <template #option="{ disposition_name }">
                          <span>{{ disposition_name }}</span>
                        </template>
                        <template #no-options="">
                          No Available Disposition
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2 mb-2"
                >
                  <b-form-checkbox
                    id="is_close"
                    v-model="customer.is_close"
                    name="is_close"
                    value="1"
                    unchecked-value="0"
                    :disabled="(state.busy || customer.call_status === null || customer.call_status === 'Unsuccessful')"
                  >
                    <strong>MARK THIS CALL REQUEST AS CLOSE</strong>
                  </b-form-checkbox>
                </b-col>

                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required|max:1000"
                  >
                    <b-form-group>
                      <label for="remarks"><strong>Remarks</strong></label>
                      <b-textarea
                        id="remarks"
                        v-model="customer.remarks"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter remarks"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>

          </b-tabs>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || state.feedBrandHistoryEditing || state.liveStockHistoryEditing || state.cropPriceEditing)"
          @click="ok()"
        >
          Update Record
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-communication-log-detail"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Channel</label>
            <b-input
              :value="selected.log?.channel"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Call Status</label>
            <b-input
              :value="selected.log?.call_status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Unsuccessful Reason</label>
            <b-input
              :value="selected.log?.unsuccessful_reason_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AgentCallRequestService, SharedLogService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'AgentPendingCalls',

  middleware: ['auth', 'agent'],

  mixins: [formatter, misc],

  metaInfo () {
    return {
      title: 'Pending Calls'
    }
  },

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        liveStockHistoryEditing: false,
        feedBrandHistoryEditing: false
      },
      list: {
        lead_tags: [],
        occupations: [],
        provinces: [],
        municipalities: [],
        barangays: [],
        dealers: [],
        dialects: [],
        live_stocks: [],
        live_stock_categories: [],
        live_stock_types: [],
        feed_brands: [],
        custom_fields: [],
        unsuccessful_reasons: [],
        disposition_types: [],
        dispositions: []
      },
      options: {
        genders: [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' }
        ],
        customer_types: [
          { text: 'Raiser', value: 'Raiser' },
          { text: 'Non-Raiser', value: 'Non-Raiser' }
        ],
        purchasing_behaviors: [
          { text: 'Per Kilo', value: 'Per Kilo' },
          { text: 'Per Bag', value: 'Per Bag' }
        ],
        purchase_days: [
          { text: 'Monday', value: 'Monday' },
          { text: 'Tuesday', value: 'Tuesday' },
          { text: 'Wednesday', value: 'Wednesday' },
          { text: 'Thursday', value: 'Thursday' },
          { text: 'Friday', value: 'Friday' },
          { text: 'Saturday', value: 'Saturday' },
          { text: 'Sunday', value: 'Sunday' }
        ],
        purchase_times: [
          { text: 'Morning', value: 'Morning' },
          { text: 'Noon', value: 'Noon' },
          { text: 'Afternoon', value: 'Afternoon' }
        ],
        digital_levels: [
          { text: 'Low', value: 'Low' },
          { text: 'Medium', value: 'Medium' },
          { text: 'High', value: 'High' }
        ],
        service_products: [
          { text: 'Services', value: 'Services' },
          { text: 'Products', value: 'Products' },
          { text: 'Services & Products', value: 'Services & Products' }
        ],
        mode_of_communications: [
          { text: 'Text', value: 'Text' },
          { text: 'Call', value: 'Call' },
          { text: 'Call & Text', value: 'Call & Text' },
          { text: 'Social Media', value: 'Social Media' },
          { text: 'Face to Face', value: 'Face to Face' }
        ],
        call_statuses: [
          { text: 'Connected', value: 'Connected' },
          { text: 'Unsuccessful', value: 'Unsuccessful' }
        ]
      },
      selected: {
        call_request: null,
        current: null,
        log: null,
        visitation_log: null,
        province: null,
        municipality: null,
        barangay: null,
        hog_raiser_category: null,
        poultry_raiser_category: null,
        call_status: null,
        unsuccessful_reason: null,
        disposition_type: null,
        disposition: null
      },
      selected2: {
        current: null,
        live_stock: null,
        live_stock_type: null
      },
      selected3: {
        current: null,
        live_stock: null,
        feed_brand: null
      },
      customer: {
        call_request_id: 0,
        id: 0,
        first_name: '',
        last_name: '',
        customer_type: null,
        lead_tag: null,
        mobile_number: '',
        email: '',
        gender: null,
        birth_date: null,
        primary_dialect: null,
        secondary_dialect: null,
        occupation: null,
        other_occupation: '',
        dealers: [],
        other_dealer: '',
        street_name: '',
        province: null,
        municipality: null,
        barangay: null,
        farm_address: '',
        promotion: null,
        purchasing_behavior: null,
        purchase_day: null,
        purchase_time: null,
        digital_level: null,
        mode_of_communication: null,
        hog_raiser_category: null,
        poultry_raiser_category: null,
        custom_fields: [],
        dirty_custom_fields: [],
        live_stock_histories: [],
        dirty_live_stock_histories: [],
        feed_brand_histories: [],
        dirty_feed_brand_histories: [],
        call_status: null,
        unsuccessful_reason: null,
        disposition_type: null,
        disposition: null,
        remarks: '',
        is_close: 0
      },
      customField: {
        field: null,
        field_value: ''
      },
      liveStockHistory: {
        id: 0,
        active: 0,
        live_stock: null,
        live_stock_name: null,
        live_stock_type: null,
        live_stock_type_name: null,
        live_stock_count: 0,
        created_at: null,
        updated_at: null
      },
      feedBrandHistory: {
        id: 0,
        active: 0,
        live_stock: null,
        live_stock_name: null,
        feed_brand: null,
        feed_brand_name: null,
        created_at: null,
        updated_at: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'status', sortable: true },
          { key: 'customer.first_name', label: 'first name' },
          { key: 'customer.last_name', label: 'last name' },
          { key: 'customer.mobile_number', label: 'mobile number' },
          { key: 'lead_name', label: 'requested by' },
          { key: 'call_attempt', label: 'unsuccessful call attempt' },
          { key: 'last_call', formatter: this.dateTimeFormatter },
          { key: 'last_status' },
          { key: 'last_agent' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      tableLiveStockHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'live_stock_name', label: 'live stock' },
          { key: 'live_stock_type_name', label: 'live stock name' },
          { key: 'live_stock_count', label: 'count' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableFeedBrandHistories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'live_stock_name', label: 'live stock' },
          { key: 'feed_brand_name', label: 'brand name' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableCommunicationLogSettings: {
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'agent_name', label: 'agent' },
          { key: 'channel' },
          { key: 'call_status' },
          { key: 'unsuccessful_reason_name', label: 'unsuccessful reason' },
          { key: 'disposition_type_name', label: 'disposition type' },
          { key: 'disposition_name', label: 'disposition' }
        ]
      },
      tableCallRequestLogSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'agent_name', label: 'agent' },
          { key: 'channel' },
          { key: 'call_status' },
          { key: 'unsuccessful_reason_name', label: 'unsuccessful reason' },
          { key: 'disposition_type_name', label: 'disposition type' },
          { key: 'disposition_name', label: 'disposition' }
        ]
      },
      allComments: ''
    }
  },

  computed: {
    filterLeadTags () {
      return [{ value: 'All', text: 'All' }].concat(this.list.lead_tags)
    },

    rulesUnsuccessfulReason () {
      if (this.customer.call_status === 'Unsuccessful') {
        return 'required'
      }

      return ''
    },

    rulesDispositionType () {
      if (this.customer.call_status === 'Connected') {
        return 'required'
      }

      return ''
    },

    rulesDisposition () {
      if (this.customer.call_status === 'Connected') {
        return 'required'
      }

      return ''
    },

    filteredHogRaiserCategories () {
      return this.list.live_stock_categories.filter(item => item.live_stock_id === 1)
    },

    filteredPoultryRaiserCategories () {
      return this.list.live_stock_categories.filter(item => item.live_stock_id === 2)
    },

    customFieldInserButttonDisabledState () {
      return !(this.customField.field && this.customField.field_value.length > 0)
    },

    filteredLiveStockHistories () {
      return this.customer.live_stock_histories.filter(item => item.active === 1)
    },

    liveStockHistoryButtonLabel () {
      if (this.state.liveStockHistoryEditing) {
        return 'Update Detail'
      }

      return 'Insert Detail'
    },

    liveStockHistoryButtonVariant () {
      if (this.state.liveStockHistoryEditing) {
        return 'secondary'
      }

      return 'success'
    },

    liveStockHistoryButtonDisabledState () {
      return !(this.liveStockHistory.live_stock && this.liveStockHistory.live_stock_type)
    },

    filteredFeedBrandHistories () {
      return this.customer.feed_brand_histories.filter(item => item.active === 1)
    },

    feedBrandHistoryButtonLabel () {
      if (this.state.feedBrandHistoryEditing) {
        return 'Update Detail'
      }

      return 'Insert Detail'
    },

    feedBrandHistoryButtonVariant () {
      if (this.state.feedBrandHistoryEditing) {
        return 'secondary'
      }

      return 'success'
    },

    feedBrandHistoryButtonDisabledState () {
      return !(this.feedBrandHistory.live_stock && this.feedBrandHistory.feed_brand)
    }
  },

  watch: {
    'selected.province' (value) {
      this.customer.province = value?.id || null
      this.getMunicipalities(this.customer.province)
    },

    'selected.filter_province' (value) {
      this.filter.province = value?.id || null
      this.getFilterMunicipalities(value?.id || null)
      this.onFilterChanged()
    },

    'selected.municipality' (value) {
      this.customer.municipality = value?.id || null
      this.getBarangays(this.customer.municipality)
    },

    'selected.filter_municipality' (value) {
      this.filter.municipality = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.barangay' (value) {
      this.customer.barangay = value?.id || null
    },

    'selected.hog_raiser_category' (value) {
      this.customer.hog_raiser_category = value?.id || null
    },

    'selected.poultry_raiser_category' (value) {
      this.customer.poultry_raiser_category = value?.id || null
    },

    'selected.call_status' (value) {
      this.customer.call_status = value?.value || null
      if (!this.customer.call_status) {
        this.selected.unsuccessful_reason = null
        this.selected.disposition_type = null
        this.selected.disposition = null
        this.selected.promotion = null
      }

      if (this.customer.call_status === 'Connected') {
        this.selected.unsuccessful_reason = null
      }

      if (this.customer.call_status === 'Unsuccessful') {
        this.selected.disposition_type = null
        this.selected.disposition = null
        this.customer.is_close = 0
      }
    },

    'selected.unsuccessful_reason' (value) {
      this.customer.unsuccessful_reason = value?.id || null
    },

    'selected.filter_unsuccessful_reason' (value) {
      this.filter.unsuccessful_reason = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.disposition_type' (value) {
      this.customer.disposition_type = value?.id || null
      this.getDispositions(this.customer.disposition_type)
    },

    'selected.filter_disposition_type' (value) {
      this.filter.disposition_type = value?.id || 'all'
      this.getFilterDispositions(value?.id || null)
      this.onFilterChanged()
    },

    'selected.filter_disposition' (value) {
      this.filter.disposition = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.disposition' (value) {
      this.customer.disposition = value?.id || null
    },

    'selected2.live_stock' (value) {
      this.liveStockHistory.live_stock = value?.id || null
      this.getLiveStockTypes(this.liveStockHistory.live_stock)
    },

    'selected2.live_stock_type' (value) {
      this.liveStockHistory.live_stock_type = value?.id || null
    },

    'selected3.live_stock' (value) {
      this.feedBrandHistory.live_stock = value?.id || null
      this.getFeedBrands(this.feedBrandHistory.live_stock)
    },

    'selected3.feed_brand' (value) {
      this.feedBrandHistory.feed_brand = value?.id || null
    }
  },

  mounted () {
    core.index()
    this.getLeadTags()
    this.getOccupations()
    this.getProvinces()
    this.getDialects()
    this.getUnsuccessfulReasons()
    this.getDispositionTypes()
    this.getCustomFields()
    this.getLiveStocks()
    this.getLiveStockCategories()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AgentCallRequestService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getCustomerLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.customer.id
          })
        )
        this.tableCommunicationLogSettings.totalRows = data.total_rows
        this.allComments = ''
        data.items.forEach(log => {
          const remarks = `${this.dateTimeFormatterLong(log.created_at)} [${log.agent_name} thru ${log.channel}]: ${log.remarks}\r\n\r\n`
          this.allComments += remarks
        })
        return data.items
      } catch (e) {
        return []
      }
    },

    async myCallRequestLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getCustomerLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.selected.call_request.customer?.id,
            request_id: this.selected.call_request.id
          })
        )
        this.tableCallRequestLogSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getProvinces () {
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data

          if (this.state.editing) {
            if (this.selected.current.municipality) {
              this.selected.municipality = {
                id: this.selected.current.municipality.id,
                municipality_name: this.selected.current.municipality.municipality_name
              }
              this.selected.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay = null
      if (municipality) {
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data

          if (this.state.editing) {
            if (this.selected.current.barangay) {
              this.selected.barangay = {
                id: this.selected.current.barangay.id,
                barangay_name: this.selected.current.barangay.barangay_name
              }
              this.selected.current.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    async getLeadTags () {
      await SharedListService.getLeadTags().then(({ data }) => {
        this.list.lead_tags = data.map(item => {
          return {
            text: item.tag_name,
            value: item.id
          }
        })
      })
    },

    async getOccupations () {
      await SharedListService.getOccupations().then(({ data }) => {
        this.list.occupations = data.map(item => {
          return {
            text: item.occupation_name,
            value: item.id
          }
        })
      })
    },

    async getDealers () {
      await SharedListService.getDealers().then(({ data }) => {
        this.list.dealers = data
      })
    },

    async getLiveStocks () {
      await SharedListService.getLiveStocks().then(({ data }) => {
        this.list.live_stocks = data
      })
    },

    async getLiveStockCategories () {
      await SharedListService.getLiveStockCategories().then(({ data }) => {
        this.list.live_stock_categories = data
      })
    },

    async getLiveStockTypes (liveStock) {
      this.selected2.live_stock_type = null
      if (liveStock) {
        await SharedListService.getLiveStockTypes(`live_stock_id=${liveStock}`).then(({ data }) => {
          this.list.live_stock_types = data
          if (this.state.liveStockHistoryEditing) {
            this.selected2.live_stock_type = _.find(this.list.live_stock_types, { id: this.selected2.current.live_stock_type })
          }
        })
      } else {
        this.list.live_stock_types = []
      }
    },

    async getFeedBrands (liveStock) {
      this.selected3.feed_brand = null
      if (liveStock) {
        await SharedListService.getFeedBrands(`live_stock_id=${liveStock}`).then(({ data }) => {
          this.list.feed_brands = data
          if (this.state.feedBrandHistoryEditing) {
            this.selected3.feed_brand = _.find(this.list.feed_brands, { id: this.selected3.current.feed_brand })
          }
        })
      } else {
        this.list.feed_brands = []
      }
    },

    async getDialects () {
      await SharedListService.getDialects().then(({ data }) => {
        this.list.dialects = data.map(item => {
          return {
            text: item.dialect_name,
            value: item.id
          }
        })
      })
    },

    async getCustomFields () {
      await SharedListService.getCustomFields().then(({ data }) => {
        this.list.custom_fields = data.map(item => {
          return {
            text: item.field_label,
            value: item.id
          }
        })
      })
    },

    async getUnsuccessfulReasons () {
      await SharedListService.getUnsuccessfulReasons().then(({ data }) => {
        this.list.unsuccessful_reasons = data
      })
    },

    async getDispositionTypes () {
      await SharedListService.getDispositionTypes(`channel_id=${this.customer.channel}`).then(({ data }) => {
        this.list.disposition_types = data
      })
    },

    async getDispositions (dispositionType) {
      this.selected.disposition = null
      if (dispositionType) {
        await SharedListService.getDispositions(`disposition_type_id=${dispositionType}`).then(({ data }) => {
          this.list.dispositions = data
        })
      } else {
        this.list.dispositions = []
      }
    },

    onShowDetailForm (current) {
      this.selected.call_request = { ...current }
      this.$bvModal.show('modal-view-request-detail')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.province = null
      this.selected.hog_raiser_category = null
      this.selected.poultry_raiser_category = null
      this.selected.call_status = null
      this.selected.unsuccessful_reason = null
      this.selected.disposition_type = null
      this.selected.disposition = null
      this.customer.call_request_id = current.id
      this.customer.is_close = 0
      this.customer.id = current.customer?.id
      this.customer.active = current.customer?.active
      this.customer.first_name = current.customer?.first_name
      this.customer.last_name = current.customer?.last_name
      this.customer.customer_type = current.customer?.customer_type
      this.customer.lead_tag = current.customer?.lead_tag?.id || null
      this.customer.mobile_number = current.customer?.mobile_number
      this.customer.email = current.customer?.email
      this.customer.birth_date = current.customer?.birth_date
      this.customer.gender = current.customer?.gender
      this.customer.primary_dialect = current.customer?.primary_dialect?.id || null
      this.customer.secondary_dialect = current.customer?.secondary_dialect?.id || null
      this.customer.occupation = current.customer?.occupation?.id || null
      this.customer.other_occupation = current.customer?.other_occupation
      this.customer.dealers = current.customer?.dealers
      this.customer.other_dealer = current.customer?.other_dealer
      this.customer.cluster = current.customer?.cluster_id
      this.customer.area = current.customer?.area_id
      this.customer.street_name = current.customer?.street_name
      this.customer.province = current.customer?.province?.id || null
      this.customer.municipality = current.customer?.municipality?.id || null
      this.customer.barangay = current.customer?.barangay?.id || null
      this.customer.farm_address = current.customer?.farm_address
      this.customer.purchasing_behavior = current.customer?.purchasing_behavior
      this.customer.purchase_day = current.customer?.purchase_day
      this.customer.purchase_time = current.customer?.purchase_time
      this.customer.digital_level = current.customer?.digital_level
      this.customer.service_product = current.customer?.service_product
      this.customer.mode_of_communication = current.customer?.mode_of_communication
      this.customer.custom_fields = current.customer?.details
      this.customer.remarks = ''

      this.customer.live_stock_histories = current.customer?.live_stock_histories.map(item => {
        return {
          id: item.id,
          active: item.active,
          live_stock: item.live_stock_id,
          live_stock_name: item.live_stock ? item.live_stock.live_stock_name : null,
          live_stock_type: item.live_stock_type_id,
          live_stock_type_name: item.live_stock_type ? item.live_stock_type.type_name : null,
          live_stock_count: item.live_stock_count,
          created_at: item.created_at,
          updated_at: item.updated_at
        }
      })

      this.customer.feed_brand_histories = current.customer?.feed_brand_histories.map(item => {
        return {
          id: item.id,
          active: item.active,
          live_stock: item.live_stock_id,
          live_stock_name: item.live_stock ? item.live_stock.live_stock_name : null,
          feed_brand: item.feed_brand_id,
          feed_brand_name: item.feed_brand ? item.feed_brand.brand_name : null,
          created_at: item.created_at,
          updated_at: item.updated_at
        }
      })

      // this.customer.call_status = null
      // this.customer.unsuccessful_reason = null
      // this.customer.disposition_type = null
      // this.customer.disposition = null
      this.customer.remarks = ''

      if (current.customer?.province) {
        this.selected.province = {
          id: current.customer?.province.id,
          province_name: current.customer?.province.province_name
        }
      }

      if (current.customer?.raiser_category_one) {
        this.selected.hog_raiser_category = {
          id: current.customer?.raiser_category_one.id,
          category_name: current.customer?.raiser_category_one.category_name
        }
      }

      if (current.customer?.raiser_category_two) {
        this.selected.poultry_raiser_category = {
          id: current.customer?.raiser_category_two.id,
          category_name: current.customer?.raiser_category_two.category_name
        }
      }

      // reset state
      this.state.liveStockHistoryEditing = false
      this.state.feedBrandHistoryEditing = false

      // reset details
      this.onCustomFieldReset()
      this.onLiveStockHistoryReset()
      this.onFeedBrandHistoryReset()

      // reset dirty
      this.customer.dirty_custom_fields = []
      this.customer.dirty_live_stock_histories = []
      this.customer.dirty_feed_brand_histories = []

      // show modal form
      this.$bvModal.show('modal-edit-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to save changes?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AgentCallRequestService.put(this.customer).then(({ data }) => {
          this.$bvModal.hide('modal-edit-form')
          this.state.busy = false
          this.$swal
            .fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            })
            .then(() => {
              const row = _.find(this.$refs.table.localItems, { id: this.customer.call_request_id })

              if (parseInt(this.customer.is_close) === 1) {
                this.$refs.table.refresh()
              } else {
                row.status = data.call_request.status
                row.call_attempt = data.call_request.call_attempt
                row.last_call = data.call_request.last_call
                row.last_status = data.call_request.last_status
                row.last_agent = data.call_request.last_agent
                row.updated_at = data.call_request.updated_at
                row.customer.active = data.customer.active
                row.customer.first_name = data.customer.first_name
                row.customer.last_name = data.customer.last_name
                row.customer.customer_type = data.customer.customer_type
                row.customer.lead_tag = data.customer.lead_tag
                row.customer.mobile_number = data.customer.mobile_number
                row.customer.email = data.customer.email
                row.customer.birth_date = data.customer.birth_date
                row.customer.gender = data.customer.gender
                row.customer.primary_dialect = data.customer.primary_dialect
                row.customer.secondary_dialect = data.customer.secondary_dialect
                row.customer.occupation = data.customer.occupation
                row.customer.other_occupation = data.customer.other_occupation
                row.customer.dealers = data.customer.dealers
                row.customer.other_dealer = data.customer.other_dealer
                row.customer.province = data.customer.province
                row.customer.municipality = data.customer.municipality
                row.customer.barangay = data.customer.barangay
                row.customer.farm_address = data.customer.farm_address
                row.customer.purchasing_behavior = data.customer.purchasing_behavior
                row.customer.purchase_day = data.customer.purchase_day
                row.customer.purchase_time = data.customer.purchase_time
                row.customer.digital_level = data.customer.digital_level
                row.customer.mode_of_communication = data.customer.mode_of_communication
                row.customer.raiser_category_one = data.customer.raiser_category_one
                row.customer.raiser_category_two = data.customer.raiser_category_two
                row.customer.details = data.details
                row.customer.live_stock_histories = data.live_stock_histories
                row.customer.feed_brand_histories = data.feed_brand_histories
                row.customer.last_call = data.customer.last_call
                row.customer.last_call_status = data.customer.last_call_status
                row.customer.last_agent_name = data.customer.last_agent_name
                row.customer.disposition_type_name = data.customer.disposition_type_name
                row.customer.disposition_name = data.customer.disposition_name
                row.customer.unsuccessful_reason_name = data.customer.unsuccessful_reason_name
                row.customer.updated_at = data.customer.updated_at
              }
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                  title: 'Validation Error',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'danger',
                  centered: true
                })
                resolve(error)
              }
            }
          })
      })
    },

    onCustomFieldReset () {
      this.customField.field = null
      this.customField.field_value = ''
    },

    async onCustomFieldRefresh () {
      this.getCustomFields()
    },

    async onCustomFieldInsert () {
      this.customer.custom_fields.push({
        id: this.generateId(10),
        field: this.customField.field,
        field_label: _.find(this.list.custom_fields, { value: this.customField.field })?.text || null,
        field_value: this.customField.field_value
      })

      this.onCustomFieldReset()
    },

    async onCustomFieldRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      this.customer.custom_fields = _.remove(this.customer.custom_fields, function (o) {
        return o.id.toString() !== current.id.toString()
      })
    },

    onLiveStockHistoryReset () {
      this.selected2.live_stock = null
      this.liveStockHistory.id = 0
      this.liveStockHistory.active = 0
      this.liveStockHistory.live_stock = null
      this.liveStockHistory.live_stock_name = null
      this.liveStockHistory.live_stock_type = null
      this.liveStockHistory.live_stock_type_name = null
      this.liveStockHistory.live_stock_count = 0
      this.liveStockHistory.created_at = null
      this.liveStockHistory.updated_at = null
    },

    onLiveStockHistoryButtonClick () {
      if (this.state.liveStockHistoryEditing) {
        this.onLiveStockHistoryUpdate()
        return
      }

      this.onLiveStockHistoryInsert()
    },

    onLiveStockHistoryInsert () {
      const newId = this.generateId(10)

      const liveStock = _.find(this.list.live_stocks, { id: this.liveStockHistory.live_stock })
      const liveStockType = _.find(this.list.live_stock_types, { id: this.liveStockHistory.live_stock_type })

      this.liveStockHistory.id = newId
      this.liveStockHistory.active = 1
      this.liveStockHistory.live_stock_name = liveStock?.live_stock_name
      this.liveStockHistory.live_stock_type_name = liveStockType?.type_name

      this.customer.live_stock_histories.push({
        ...this.liveStockHistory
      })

      this.onLiveStockHistoryReset()
    },

    onLiveStockHistoryEdit (current) {
      this.state.liveStockHistoryEditing = true
      this.selected2.current = { ...current }
      this.liveStockHistory.id = current.id
      this.liveStockHistory.active = current.active
      this.liveStockHistory.live_stock = current.live_stock
      this.liveStockHistory.live_stock_name = current.live_stock_name
      this.liveStockHistory.live_stock_type = current.live_stock_type
      this.liveStockHistory.live_stock_type_name = current.live_stock_type_name
      this.selected2.live_stock = _.find(this.list.live_stocks, { id: current.live_stock })
    },

    onLiveStockHistoryUpdate () {
      const liveStockHistory = _.find(this.customer.live_stock_histories, { id: this.liveStockHistory.id })
      const liveStock = _.find(this.list.live_stocks, { id: this.liveStockHistory.live_stock })
      const liveStockType = _.find(this.list.live_stock_types, { id: this.liveStockHistory.live_stock_type })

      this.liveStockHistory.live_stock_name = liveStock?.live_stock_name
      this.liveStockHistory.live_stock_type_name = liveStockType?.type_name

      if (liveStockHistory) {
        liveStockHistory.live_stock = this.liveStockHistory.live_stock
        liveStockHistory.live_stock_name = this.liveStockHistory.live_stock_name
        liveStockHistory.live_stock_type = this.liveStockHistory.live_stock_type
        liveStockHistory.live_stock_type_name = this.liveStockHistory.live_stock_type_name
        liveStockHistory.live_stock_count = this.liveStockHistory.live_stock_count
        // add to dirty list
        if (_.indexOf(this.customer.dirty_live_stock_histories, this.liveStockHistory.id) < 0) {
          this.customer.dirty_live_stock_histories.push(this.liveStockHistory.id)
        }
        this.state.liveStockHistoryEditing = false
        this.onLiveStockHistoryReset()
      }
    },

    onLiveStockHistoryCancelUpdate () {
      this.state.liveStockHistoryEditing = false
      this.onLiveStockHistoryReset()
    },

    onLiveStockHistoryRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      const liveStockHistory = _.find(this.customer.live_stock_histories, { id: current.id })
      liveStockHistory.active = 0
    },

    onFeedBrandHistoryReset () {
      this.selected3.live_stock = null
      this.feedBrandHistory.id = 0
      this.feedBrandHistory.active = 0
      this.liveStockHistory.live_stock = null
      this.liveStockHistory.live_stock_name = null
      this.liveStockHistory.feed_brand = null
      this.liveStockHistory.feed_brand_name = null
      this.feedBrandHistory.created_at = null
      this.feedBrandHistory.updated_at = null
    },

    onFeedBrandHistoryButtonClick () {
      if (this.state.feedBrandHistoryEditing) {
        this.onFeedBrandHistoryUpdate()
        return
      }

      this.onFeedBrandHistoryInsert()
    },

    onFeedBrandHistoryInsert () {
      const newId = this.generateId(10)

      const liveStock = _.find(this.list.live_stocks, { id: this.feedBrandHistory.live_stock })
      const feedBrand = _.find(this.list.feed_brands, { id: this.feedBrandHistory.feed_brand })

      this.feedBrandHistory.id = newId
      this.feedBrandHistory.active = 1
      this.feedBrandHistory.live_stock_name = liveStock?.live_stock_name
      this.feedBrandHistory.feed_brand_name = feedBrand?.brand_name

      this.customer.feed_brand_histories.push({
        ...this.feedBrandHistory
      })

      this.onFeedBrandHistoryReset()
    },

    onFeedBrandHistoryEdit (current) {
      this.selected3.current = { ...current }
      this.state.feedBrandHistoryEditing = true
      this.feedBrandHistory.id = current.id
      this.feedBrandHistory.active = current.active
      this.feedBrandHistory.live_stock = current.live_stock
      this.feedBrandHistory.live_stock_name = current.live_stock_name
      this.feedBrandHistory.feed_brand = current.feed_brand
      this.feedBrandHistory.feed_brand_name = current.feed_brand_name
      this.selected3.live_stock = _.find(this.list.live_stocks, { id: current.live_stock })
    },

    onFeedBrandHistoryUpdate () {
      const feedBrandHistory = _.find(this.customer.feed_brand_histories, { id: this.feedBrandHistory.id })
      const liveStock = _.find(this.list.live_stocks, { id: this.feedBrandHistory.live_stock })
      const feedBrand = _.find(this.list.feed_brands, { id: this.feedBrandHistory.feed_brand })

      this.feedBrandHistory.live_stock_name = liveStock?.live_stock_name
      this.feedBrandHistory.feed_brand_name = feedBrand?.brand_name

      if (feedBrandHistory) {
        feedBrandHistory.live_stock = this.feedBrandHistory.live_stock
        feedBrandHistory.live_stock_name = this.feedBrandHistory.live_stock_name
        feedBrandHistory.feed_brand = this.feedBrandHistory.feed_brand
        feedBrandHistory.feed_brand_name = this.feedBrandHistory.feed_brand_name

        // add to dirty list
        if (_.indexOf(this.customer.dirty_feed_brand_histories, this.feedBrandHistory.id) < 0) {
          this.customer.dirty_feed_brand_histories.push(this.feedBrandHistory.id)
        }
        this.state.feedBrandHistoryEditing = false
        this.onFeedBrandHistoryReset()
      }
    },

    onFeedBrandHistoryCancelUpdate () {
      this.state.feedBrandHistoryEditing = false
      this.onFeedBrandHistoryReset()
    },

    onFeedBrandHistoryRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      this.customer.feed_brand_histories = _.remove(this.customer.feed_brand_histories, function (o) {
        return o.id.toString() !== current.id.toString()
      })
    },

    onViewCommunicationLogDetail (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-communication-log-detail')
    },

    onViewCallRequestLog (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-call-request-log')
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
